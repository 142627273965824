import {useEffect, useState} from "react"
import {BooksitoutServer} from "../../config/BooksitoutServer"
import BookResponse from "./BookResponse"

const useBookLastRead = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const [book, setBook] = useState<BookResponse | null>(null)
    
    useEffect(() => {
        BooksitoutServer
            .get(`/v1/book/last`)
            .then((res) => setBook(res.data))
            .finally(() => setIsLoading(false))
    }, [])

    return [book, isLoading] as const
}

export default useBookLastRead