import {Button} from 'react-bootstrap';
import styled from 'styled-components';
import breakpoints from '../../../config/Breakpoints';
import useFeedbackStore from "../../feedback/useFeedbackStore";

const IndexAfterReleaseCard = () => {
    const {open} = useFeedbackStore()

    return (
        <Container>
            <Title>책잇아웃에 대한 생각을 들려 주세요</Title>

            <Content>
                사용하면서 불편했던 점이나 개선해야 할 부분을 알려주세요
            </Content>

            <InputButton onClick={open}>피드백 남기기</InputButton>
        </Container>
    )
}

const Container = styled.div.attrs({
    className: 'rounded'
})`
    min-height: 200px;
    background-color: #d1e7de;

    padding: 30px;
    padding-top: 40px;

    text-align: center;
`;

const Title = styled.h1`
    font-size: 25px;
    font-weight: 700;
`;

const Content = styled.p.attrs({
    className: 'text-secondary'
})`
    font-size: 20px;
`;

const InputButton = styled(Button).attrs({
    variant: 'book',
})`
    width: 300px;
    margin-left: 20px;

    @media screen and (max-width: ${breakpoints.sm}) {
        width: 100%;

        margin-left: 0;
        margin-top: 20px;
    }
`;

export default IndexAfterReleaseCard