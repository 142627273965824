import React, {useEffect, useRef} from 'react'
import styled from 'styled-components';
import booksitoutIcon from "../../../config/BooksitoutIcon";
import breakpoints from "../../../config/Breakpoints";
import ColorConfig from "../../../config/ColorConfig";
import SearchHistoryResponse from "../../../common/response/SearchHistoryResponse";
import AutoCompleteResponse from "../../../common/response/AutoCompleteResponse";

interface Props {
    placeholder: string

    query: string
    queryHistories: SearchHistoryResponse[]
    querySuggestions: AutoCompleteResponse[],

    isShowingQueryHistory: boolean
    isShowingAutoComplete: boolean
    isFocusOnAppear?: boolean
    isShouldUpdateQueryParam: boolean

    setQuery: (query: string) => void
    setIsShowingQueryHistory: (isShowingQueryHistory: boolean) => void
    setIsShowingAutoComplete: (isShowingAutoComplete: boolean) => void
    handleSubmit: (event: React.FormEvent<HTMLFormElement>, focus: () => void) => void
    addFirstItemOnSubmit: ((item: any) => void) | null

    handleQuerySuggestionClick?: (suggestion: AutoCompleteResponse) => void
}

let defaultHandleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
}

const SearchBar: React.FC<Props> = ({
                                        placeholder,

                                        query,
                                        queryHistories,
                                        querySuggestions,

                                        isShowingQueryHistory,
                                        isShowingAutoComplete,
                                        isFocusOnAppear,
                                        isShouldUpdateQueryParam,

                                        setQuery,
                                        setIsShowingQueryHistory,
                                        setIsShowingAutoComplete,
                                        handleSubmit = defaultHandleSubmit,
                                        addFirstItemOnSubmit,

                                        handleQuerySuggestionClick
                                    }) => {
    const htmlInputRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (htmlInputRef.current && isFocusOnAppear) {
            htmlInputRef.current.focus()
        }
    }, []);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value ?? ''

        setQuery(value)

        if (!isShouldUpdateQueryParam) {
            return
        }

        if (value) {
            window.history.pushState({}, '', `?q=${value}`)
        } else {
            window.history.pushState({}, '', window.location.pathname)
        }
    }

    const handleOnFocus = () => {
        if (htmlInputRef.current) {
            htmlInputRef.current.focus()
        }

        setIsShowingAutoComplete(true)
        setIsShowingQueryHistory(true)
    }

    const handleOnBlur = () => {
        setTimeout(() => {
            setIsShowingAutoComplete(false)
            setIsShowingQueryHistory(false)
        }, 200)
    }

    return (
        // @ts-ignore
        <SearchForm onSubmit={(e) => {
            handleSubmit(e, handleOnFocus)
            if (addFirstItemOnSubmit && querySuggestions.length > 0) {
                addFirstItemOnSubmit(querySuggestions[0])
            }
        }} style={{width: '100%'}}>
            <Input
                // @ts-ignore
                ref={htmlInputRef}
                placeholder={placeholder}
                value={query}
                onChange={handleInputChange}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
            />

            <SearchButton type="submit">
                <span><booksitoutIcon.search/></span>
            </SearchButton>

            {
                isShowingQueryHistory && queryHistories &&
                <AutocompleteBox>
                    {
                        queryHistories
                            .map((queryHistory) =>
                                <SuggestionLink href={queryHistory.url} key={crypto.randomUUID()}>
                                    {queryHistory.imageUrl && <SuggestionImage src={queryHistory.imageUrl}/>}
                                    <SuggestionName>{queryHistory.query}</SuggestionName>
                                </SuggestionLink>
                            )
                    }
                </AutocompleteBox>
            }

            {
                isShowingAutoComplete &&
                <AutocompleteBox>
                    {
                        handleQuerySuggestionClick ?
                            querySuggestions
                                .map((suggestion) =>
                                    <Suggestion key={crypto.randomUUID()}
                                                onClick={() => handleQuerySuggestionClick(suggestion)}>
                                        <SuggestionImage src={suggestion.imageUrl}/>
                                        <SuggestionName>{suggestion.name}</SuggestionName>

                                        {
                                            suggestion.secondaryName &&
                                            <SuggestionSecondaryName>{suggestion.secondaryName}</SuggestionSecondaryName>
                                        }
                                    </Suggestion>
                                )
                            :
                            querySuggestions
                                .map((suggestion) =>
                                    <SuggestionLink href={suggestion.url} key={crypto.randomUUID()}>
                                        <SuggestionImage src={suggestion.imageUrl}/>
                                        <SuggestionName>{suggestion.name}</SuggestionName>

                                        {
                                            suggestion.secondaryName &&
                                            <SuggestionSecondaryName>{suggestion.secondaryName}</SuggestionSecondaryName>
                                        }
                                    </SuggestionLink>
                                )
                    }
                </AutocompleteBox>
            }
        </SearchForm>
    )
}

const Input = styled.input`
    flex-grow: 1;
    border: none;
    border-radius: 0;
    outline: none;
    padding: 10px 20px;
    font-size: 16px;

    @media screen and (max-width: ${breakpoints.md}px) {
        min-width: 240px;
        max-width: 350px;
    }
`;

const SearchButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px 10px 10px;
    color: ${ColorConfig.Primary};
`;

const SuggestionLink = styled.a`
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;

    padding: 10px 10px 10px 30px;

    &:hover {
        background-color: #f2f2f2;
    }
`;

const Suggestion = styled.div.attrs({
    className: 'clickable'
})`
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;

    padding: 10px 10px 10px 30px;

    &:hover {
        background-color: #f2f2f2;
    }
`;

const SearchForm = styled.form`
    display: flex;
    position: relative;
    align-items: center;
    border: 2px solid ${ColorConfig.PrimarySecond};
    border-radius: 10px;
    padding: 5px 10px;
`;

const AutocompleteBox = styled.div`
    position: absolute;
    width: 100%;
    top: 120%;
    left: 0;
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 1000;
    min-width: 350px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
`;


const SuggestionImage = styled.img.attrs({
    className: 'img-fluid'
})`
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 20px;
`;

const SuggestionName = styled.div`
    font-size: 16px;
    color: inherit;
    font-weight: bold;
    padding-left: 10px;
`;

const SuggestionSecondaryName = styled.div.attrs({
    className: 'text-secondary clamp-1-line'
})`
    font-size: 12px;
    color: inherit;
    font-weight: bold;
    padding-left: 5px;
    padding-top: 10px;
`;

export default SearchBar
