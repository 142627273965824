// WebsiteLogo
import aladin from './logo/aladin.png'
import interpark from './logo/interpark.png'
import kyobo from './logo/kyobo.png'
import millie from './logo/millie.png'
import ridi from './logo/ridi.png'
import yes24 from './logo/yes24.png'
import appStore from './logo/apple-appstore.png'
import googleChrome from './logo/google-chrome.png'
import naver from './logo/naver.png'
import gwangwamunLibrary from './logo/gwanghwamun-library.jpg'
import gyeonggiEducationLibrary from './logo/gyeonggi-education-library.png'
import gyeonggiLibrary from './logo/gyeonggi-library.png'
import nationalAssemblyLibrary from './logo/national-assembly-library.png'
import seoulCongressLibrary from './logo/seoul-congress-library.png'
import seoulEducationLibrary from './logo/seoul-education-library.png'
import seoulLibrary from './logo/seoul-library.png'

// LibraryLogo
import booksitout from './logo/booksitout.png'
import purrfitTracker from './logo/purrfitTracker.png'
import substrack from './logo/substrack.png'
import featuredDays from './logo/featureddaysicon.png'
import pianoMan from './logo/pianoman.png'
import simplifiedGoogleTask from './logo/simplified-google-task.png'

// Placeholder
import user from './placeholder/user.png'
import bookCover from './placeholder/default-book-cover.png'
import bookCoverLoading from './placeholder/default-book-cover-loading.png'
import libraryLoading from './placeholder/square-loading.png'

// Illustration
import relax from './illustration/relax.svg'

// Login
import appleLogin from './login-button/small-apple.png'
import facebookLogin from './login-button/small-facebook.png'
import googleLogin from './login-button/small-google.png'
import kakaoLogin from './login-button/small-kakao.png'
import naverLogin from './login-button/small-naver.png'
import samsungLogin from './login-button/small-samsung.png'
import twitterLogin from './login-button/small-twitter.png'

// Statistics
import streak from './statistics/fire-icon.svg'
import goal from './statistics/goal.png'
import goalComplete from './statistics/goal-complete.png'
import time from './statistics/time.png'
import average from './statistics/simple-chart.png'
import book from './statistics/book-overlap.png'
import star from './statistics/star.png'
import longestDay from './statistics/bookworm.png'
import page from './statistics/page.png'

// StatisticsCategory
import others from './book-classifications/categories/others.png'
import philosophy from './book-classifications/categories/philosophy.png'
import religion from './book-classifications/categories/religion.png'
import socialScience from './book-classifications/categories/social-science.png'
import naturalScience from './book-classifications/categories/natural-science.png'
import technology from './book-classifications/categories/technology.png'
import art from './book-classifications/categories/art.png'
import language from './book-classifications/categories/language.png'
import literature from './book-classifications/categories/literature.png'
import history from './book-classifications/categories/history.png'
import question from './book-classifications/categories/unknown.png'

// Language
import korean from './book-classifications/languages/korea.png'
import english from './book-classifications/languages/usa.png'
import japanese from './book-classifications/languages/japan.png'
import chinese from './book-classifications/languages/china.png'
import arabic from './book-classifications/languages/arab.png'
import spanish from './book-classifications/languages/spain.png'
import french from './book-classifications/languages/france.png'
import german from './book-classifications/languages/german.png'
import russian from './book-classifications/languages/russia.png'

const BooksitoutImages = {
    WebsiteLogo: {
        aladin: aladin,
        interpark: interpark,
        kyobo: kyobo,
        millie: millie,
        ridi: ridi,
        yes24: yes24,

        appStore: appStore,
        googleChrome: googleChrome,

        naver: naver,

        booksitout: booksitout,
        purrfitTracker: purrfitTracker,
        substrack: substrack,
        featuredDays: featuredDays,
        pianoman: pianoMan,
        simplifiedGoogleTask: simplifiedGoogleTask
    },

    LibraryLogo: {
        광화문도서관: gwangwamunLibrary,
        경기도서관: gyeonggiLibrary,
        경기교육도서관: gyeonggiEducationLibrary,
        국회도서관: nationalAssemblyLibrary,
        서울의회도서관: seoulCongressLibrary,
        서울도서관: seoulLibrary,
        서울교육도서관: seoulEducationLibrary,
    },

    Placeholder: {
        user: user,
        bookCover: bookCover,
        bookCoverLoading: bookCoverLoading,
        libraryLoading: libraryLoading,
    },

    Illustration: {
        relax: relax,
    },

    LoginButton: {
        google: googleLogin,
        kakao: kakaoLogin,
        naver: naverLogin,

        apple: appleLogin,
        facebook: facebookLogin,
        samsung: samsungLogin,
        twitter: twitterLogin,
    },

    Statistics: {
        streak: streak,
        goal: goal,
        goalComplete: goalComplete,
        time: time,
        average: average,
        book: book,
        star: star,
        longestDay: longestDay,
        page: page,
    },

    StatisticsCategory: {
        others: others,
        philosophy: philosophy,
        religion: religion,
        socialScience: socialScience,
        naturalScience: naturalScience,
        technology: technology,
        art: art,
        language: language,
        literature: literature,
        history: history,
        question: question,
    },

    Language: {
        korean: korean,
        english: english,
        japanese: japanese,
        chinese: chinese,
        arabic: arabic,
        spanish: spanish,
        french: french,
        german: german,
        russian: russian,
    }
}

export default BooksitoutImages