import React, {useEffect, useState} from 'react'
import styled from 'styled-components';
import RouteContainer from "../../../common/styles/RouteContainer";
import useAppUserProfile from "../useAppUserProfile";
import {useParams} from "react-router";
import {Pagination} from 'react-bootstrap';
import RowSpacer from "../../../common/styles/RowSpacer";
import BookMineCard from "../../book/mine/BookMineCard";
import NoContent from "../../../common/NoContent";
import ProfileUserCard from "./ProfileUserCard";
import useHtmlTitle from "../../../common/useHtmlTitle";
import BookMineDoneCard from "../../book/mine/BookMineDoneCard";
import Paging from "../../../common/hooks/Paging";
import useUrlQuery from "../../../common/hooks/useUrlQuery";

const ProfileRoute = () => {
    const {userId} = useParams()
    const pageQuery = useUrlQuery('page') ?? '1'

    const [page, setPage] = useState<number | undefined>(undefined)
    useEffect(() => {
        const parsed = parseInt(pageQuery, 10)
        setPage(!Number.isNaN(parsed) ? parsed : 1)
    }, [pageQuery])

    const [appUserWithBooks, isProfileLoading, isError, paging] = useAppUserProfile(userId, page, 24);

    useHtmlTitle(appUserWithBooks ? `${appUserWithBooks?.user.name} | 프로필` : null)

    if (isProfileLoading) {
        return <></>
    }

    if (isError || appUserWithBooks == null) {
        return <NoContent message={'유저가 없거나 탈퇴한 유저에요.'}/>
    }

    return (
        <RouteContainer>
            <RowSpacer/>
            <ProfileUserCard user={appUserWithBooks.user}/>

            <RowSpacer/>
            <Row>
                {
                    appUserWithBooks.books.length === 0 ?
                        <NoContent message={'공유 설정한 책이 없어요'}/>
                        :
                        appUserWithBooks.books.map(book =>
                            <Col>
                                {
                                    book.status == 'DONE' ?
                                        <BookMineDoneCard book={book}
                                                          url={`/users/${userId}/books/${book.id}`}/>
                                        :
                                        <BookMineCard book={book}
                                                      url={`/users/${userId}/books/${book.id}`}/>
                                }
                            </Col>
                        )
                }
            </Row>

            {
                appUserWithBooks.books.length !== 0 &&
                <Page paged={paging} currentPage={page ?? 1} url={`/users/${userId}`}/>
            }
            <RowSpacer/>

            <RowSpacer/>
        </RouteContainer>
    )
}

const Row = styled.div.attrs({
    className: 'row'
})``

const Col = styled.div.attrs({
    className: 'col-6 col-md-4 col-lg-3 col-xl-2'
})``

interface PageProps {
    paged: Paging
    currentPage: number
    url: string
}

const Page: React.FC<PageProps> = ({paged, currentPage, url}) => {

    if (paged.totalPages === 0) return <></>

    return (
        <div className='d-flex justify-content-center mb-5 mt-5'>
            <Pagination>
                {paged.totalPages > 10 && currentPage !== 1 && <Pagination.First href={`${url}?page=1`}/>}

                {
                    paged.totalPages <= 5 ?
                        Array.from({length: paged.totalPages}, (_, index) => index + 1).map((p) => {
                            return (
                                <Pagination.Item active={p == currentPage ?? 1} href={`${url}?page=${p}`}>
                                    {p}
                                </Pagination.Item>
                            )
                        })
                        :
                        Array.from({length: paged.totalPages}, (_, index) => index + 1).map((p) => {
                            const range = currentPage <= 4 ? 4 : currentPage >= (paged.totalPages - 4) ? 3 : 2

                            return (
                                <>
                                    {
                                        currentPage - range <= p && p <= currentPage + range &&
                                        <Pagination.Item active={p == currentPage ?? 1} href={`${url}?page=${p}`}>
                                            {p}
                                        </Pagination.Item>
                                    }
                                </>
                            )
                        })
                }

                {paged.totalPages > 10 && currentPage !== paged.totalPages &&
                    <Pagination.Last href={`${url}?page=${paged.totalPages}`}/>}
            </Pagination>
        </div>
    )
}

export default ProfileRoute
