import styled from 'styled-components';
import {NavDropdown} from 'react-bootstrap'
import booksitoutIcon from '../../config/BooksitoutIcon'
import useLoginStore from '../../routes/login/useLoginStore'
import breakpoints from "../../config/Breakpoints";
import BooksitoutImages from "../../images/BooksitoutImages";
import useFeedbackStore from "../../routes/feedback/useFeedbackStore";

const TopnavUserIcon = () => {
    const isLoggedIn = useLoginStore(state => state.isLoggedIn())
    const handleLogout = useLoginStore(state => state.logout)
    const iconSrc = localStorage.getItem('appUsers-image') == null || localStorage.getItem('appUsers-image') === '' ? BooksitoutImages.Placeholder.user : localStorage.getItem('appUsers-image') ?? ''

    const {open: openFeedback} = useFeedbackStore()

    return (
        <NavContainer title={<Icon src={iconSrc}/>}>
            {
                !isLoggedIn ? (
                    <>
                        <NavDropdown.Item onClick={openFeedback}>
                            <booksitoutIcon.faq className="text-book me-2 mb-1 bold"/>
                            제안 / 피드백
                        </NavDropdown.Item>

                        <NavDropdown.Divider/>

                        <NavDropdown.Item href="/login">
                            <booksitoutIcon.login className="text-book me-2 mb-1"/>
                            로그인 + 회원가입
                        </NavDropdown.Item>
                    </>
                ) : (
                    <>
                        <NavDropdown.Item onClick={openFeedback}>
                            <booksitoutIcon.suggestion className="text-book me-2 mb-1 bold"/>
                            제안 / 피드백
                        </NavDropdown.Item>

                        <NavDropdown.Divider/>

                        <NavDropdown.Item href="/users/me/redirect">
                            <booksitoutIcon.user className="text-book me-2 mb-1"/>
                            내 프로필
                        </NavDropdown.Item>

                        <NavDropdown.Item href="/settings">
                            <booksitoutIcon.settings className="text-book me-2 mb-1"/>
                            설정
                        </NavDropdown.Item>

                        <NavDropdown.Item onClick={handleLogout}>
                            <booksitoutIcon.login className="text-book me-2 mb-1"/>
                            로그아웃
                        </NavDropdown.Item>
                    </>
                )
            }
        </NavContainer>
    )
}

const NavContainer = styled(NavDropdown).attrs({
    className: 'd-md-block',
    align: 'end',
})`
    padding: 0;
    margin: 0;

    .dropdown-menu {
        top: 25px !important;
        right: auto !important;
        bottom: auto !important;
        left: auto !important;

        @media screen and (max-width: ${breakpoints.xxxl}) {
            right: 10px !important;
        }
    }
`;

const Icon = styled.img.attrs({
    className: 'img-fluid rounded',
    alt: ''
})`
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;
`;

export default TopnavUserIcon