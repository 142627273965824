import { create } from "zustand"

interface FeedbackStore {
    isOpen: boolean
    open: () => void
    close: () => void
}

const useFeedbackStore = create<FeedbackStore>((set) => ({
    isOpen: false,
    open: () => set({ isOpen: true }),
    close: () => set({ isOpen: false }),
}))

export default useFeedbackStore