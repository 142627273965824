import {useEffect, useState} from "react";
import AppUserWithBooksResponse from "./AppUserWithBooksResponse";
import {BooksitoutServer} from "../../config/BooksitoutServer";
import Paging from "../../common/hooks/Paging";
import PagedSingleResponse from "../../common/response/PagedSingleResponse";

const useAppUserProfile = (appUserIdOrPublicUrlName?: string, bookPage?: number | null, bookSize?: number) => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isError, setIsError] = useState<boolean>(false)
    const [hasMore, setHasMore] = useState<boolean>(true)
    const [totalPages, setTotalPages] = useState<number>(0)

    const [appUserWithBooks, setAppUserWithBooks] = useState<PagedSingleResponse<AppUserWithBooksResponse> | null>(null)

    useEffect(() => {
        if (appUserIdOrPublicUrlName !== undefined && bookPage !== undefined && bookPage != null) {
            BooksitoutServer
                .get(`/v1/users/${appUserIdOrPublicUrlName}?page=${bookPage - 1 ?? 0}&size=${bookSize ?? 12}`)
                .then(res => {
                    if (res.status == 204) {
                        setIsError(true)
                    } else {
                        setAppUserWithBooks(res.data)
                        setHasMore(!res.data.isLast)
                        setTotalPages(res.data.page.end)
                    }
                })
                .catch(() => setIsError(true))
                .finally(() => setIsLoading(false));
        }
    }, [bookPage])

    const paging: Paging = {
        hasMore: hasMore,
        totalPages: totalPages,
        fetchNext: () => {}
    }

    return [appUserWithBooks?.content ?? null, isLoading, isError, paging] as const
}

export default useAppUserProfile
