import React, {useEffect, useState} from 'react'
import styled from 'styled-components';
import Modal from "../../common/Modal";
import {BooksitoutServer} from "../../config/BooksitoutServer";
import RatingStar from "../../common/RatingStar";
import RowSpacer from "../../common/styles/RowSpacer";
import toast from "react-hot-toast";
import BookResponse from "./BookResponse";
import Center from "../../common/Center";
import BooksitoutButton from "../../common/button/BooksitoutButton";

interface Props {
    isModalOpen: boolean
    onClose: () => void
    updateRating: (rating: number) => void
    book: BookResponse
}

const BookRatingModal: React.FC<Props> = ({isModalOpen, onClose, updateRating, book}) => {
    const [rating, setRating] = useState<number>(1)
    useEffect(() => {
        setRating(book.rating ?? 1)
    }, [book]);

    const updateRatingToServer = () => {
        BooksitoutServer
            .put(`/v1/book/${book.id}`, {rating: rating})
            .then(() => {
                onClose()
                updateRating(rating)

                if (book.rating == null) {
                    toast.success(`책에 평점을 추가했어요.`);
                } else {
                    toast.success(`책의 평점을 수정했어요.`);
                }
            })
            .catch(() => toast.error(`오류가 났어요. 잠시 후 다시 시도해 주세요.`))
    }

    return (
        <Modal titleText={book.rating == null ? `평점 추가하기` : `평점 수정하기`} isShowing={isModalOpen} onClose={onClose} body={
            <Container>
                <RowSpacer size={10}/>

                <Center>
                    {
                        [1, 2, 3, 4, 5].map((i) =>
                            <div onClick={() => setRating(i)} className={'clickable'}>
                                <RatingStar isFilled={rating >= i} size={50}/>
                            </div>
                        )
                    }
                </Center>

                <RowSpacer/>

                <Center>
                    <BooksitoutButton message={'평점 추가하기'} onClick={updateRatingToServer}/>
                </Center>
            </Container>
        }/>
    )
}

const Container = styled.div`
`

export default BookRatingModal
