import React from 'react'
import styled from 'styled-components';
import ColorConfig from "../../../../../config/ColorConfig";
import breakpoints from "../../../../../config/Breakpoints";

interface Props {
    title: string
    subTitle?: string
    right?: JSX.Element

    isEmpty: boolean

    body: () => JSX.Element
}

const BookSourceSection: React.FC<Props> = ({title, subTitle, right, isEmpty, body}) => {
    return (
        // @ts-ignore
        <Container isEmpty={isEmpty}>
            <TitleContainer>
                <TitleSubTitleContainer>
                    <Title>{title}</Title>

                    {
                        subTitle &&
                        <SubTitle>{subTitle}</SubTitle>
                    }
                </TitleSubTitleContainer>

                {right}
            </TitleContainer>

            {body}
        </Container>
    )
}

interface ContainerProps {
    isEmpty: boolean
}

const Container = styled.div<ContainerProps>`
    padding: 20px;
    border-left: 5px solid ${props => props.isEmpty ? ColorConfig.Secondary : ColorConfig.Primary};
    min-height: 200px;
`

const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const TitleSubTitleContainer = styled.div`
    display: flex;
`

const Title = styled.h3`
    padding-bottom: 10px;
    font-weight: bold;
`

const SubTitle = styled.div.attrs({
    className: 'text-secondary clamp-1-line'
})`
    margin-left: 10px;
    margin-top: 7px;

    @media (max-width: ${breakpoints.md}) {
        max-width: 200px;
    }
`;

export default BookSourceSection