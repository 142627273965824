import {useEffect} from 'react'
import {useParams} from "react-router-dom";
import useBookDetail from "../../book/detail/useBookDetail";
import useBookDetailStore from "../../book/detail/useBookDetailStore";
import NoContent from "../../../common/NoContent";
import RouteContainer from "../../../common/styles/RouteContainer";
import RowSpacer from "../../../common/styles/RowSpacer";
import ProfileBookDetailMemoCard from "./bookDetail/ProfileBookDetailMemoCard";
import ProfileBookDetailReadingSessionCard from "./bookDetail/ProfileBookDetailReadingSessionCard";
import ProfileBookDetailInfoCard from "./bookDetail/ProfileBookDetailInfoCard";
import ProfileUserCard from "./ProfileUserCard";
import useAppUserProfile from "../useAppUserProfile";

const ProfileBookDetailRoute = () => {
    const {bookId, userId} = useParams()

    const [bookDetail, isBookLoading, isBookError] = useBookDetail(Number(bookId))
    const [appUserWithBooks, isAppUserLoading, isAppUserError] = useAppUserProfile(userId, 1, 0)

    const {init} = useBookDetailStore()
    useEffect(() => {
        if (bookDetail != null) {
            init(bookDetail)
        }
    }, [bookDetail])

    if (isBookLoading || isAppUserLoading) {
        return <></>
    }

    if (isBookError || isAppUserError || appUserWithBooks?.user == null ||  bookDetail == null) {
        return <NoContent message={"찾으시는 책이 없거나 비공개인 책이에요."}/>
    }

    return (
        <RouteContainer>
            <RowSpacer/>
            <ProfileUserCard user={appUserWithBooks.user}/>

            <RowSpacer/>
            <ProfileBookDetailInfoCard/>

            <RowSpacer size={10}/>
            <ProfileBookDetailMemoCard/>

            <RowSpacer size={10}/>
            <ProfileBookDetailReadingSessionCard/>
        </RouteContainer>
    )
}

export default ProfileBookDetailRoute
