import React, {useEffect, useRef} from 'react'
import {Form} from 'react-bootstrap'

interface Props extends React.ComponentPropsWithoutRef<typeof Form.Control> {
    isFocusOnAppear?: boolean
    height?: number
}

const TextAreaInput: React.FC<Props> = (props) => {
    const htmlInputRef = useRef<HTMLTextAreaElement>(null)
    useEffect(() => {
        if (htmlInputRef.current && props.isFocusOnAppear === true) {
            htmlInputRef.current.focus()

            const timeout = setTimeout(() => {
                htmlInputRef.current.focus()
            }, 0)
            return () => clearTimeout(timeout)
        }
    }, [htmlInputRef]);

    return (
        <Form.Control
            as={'textarea'}
            style={{height: props.height}}
            ref={htmlInputRef}
            {...props}
        />
    )
}

export default TextAreaInput
