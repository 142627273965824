import React from 'react'
import styled from 'styled-components';
import BooksitoutIcon from "../../../config/BooksitoutIcon";
import ColorConfig from "../../../config/ColorConfig";

interface Props {
    message: string
}

const BookSourceNoContent: React.FC<Props> = ({message}) => {
    return (
        <Container>
            <IconContainer>
                <BooksitoutIcon.bookXmark/>
            </IconContainer>

            <MessageContainer>
                {message}
            </MessageContainer>
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    text-align: center;
`

const IconContainer = styled.span`
    font-size: 50px;
    color: ${ColorConfig.Secondary};
`

const MessageContainer = styled.h5`
    font-weight: bold;
`

export default BookSourceNoContent