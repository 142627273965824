import { useEffect, useState } from "react"
import { BooksitoutServer } from "../../../config/BooksitoutServer"
import PopularBookResponse from "./PopularBookResponse"

export const usePopularBooks = (size: number) => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [popularBooks, setPopularBooks] = useState<PopularBookResponse[]>([])

    useEffect(() => {
        BooksitoutServer
            .get(`/v1/book/best-seller?size=${size}` )
            .then((res) => setPopularBooks(res.data))
            .finally(() => setIsLoading(false))
    }, [size])

    return [isLoading, popularBooks] as const
}