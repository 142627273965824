import React, {useEffect, useState} from 'react'
import styled from 'styled-components';
import BookSourceSection from "./common/BookSourceSection";
import useCurrentLocation from "../../../library/useCurrentLocation";
import useBookLibraryOffline from "../hooks/useBookLibraryOffline";
import {SearchBookSourceLibraryOfflineResponse} from "../SearchBookSourceLibraryResponse";
import ContentContainer from "../../../../common/styles/ContentContainer";
import RowSpacer from "../../../../common/styles/RowSpacer";
import LoanPossibleBadge from "./common/LoanPossibleBadge";
import ColSpacer from "../../../../common/styles/ColSpacer";
import BookSourceNoContent from "../BookSourceNoContent";
import BookSourceSectionLoading from "./common/BookSourceSectionLoading";
import breakpoints from "../../../../config/Breakpoints";
import useSearchSettings from "../hooks/useBookSearchSettings";
import BooksitoutIcon from '../../../../config/BooksitoutIcon';
import LoadingBar from "../../../../common/LoadingBar";
import ColorConfig from "../../../../config/ColorConfig";
import {Button} from 'react-bootstrap';

interface Props {
    isbn: string
}

const BookSourceOfflineLibrarySection: React.FC<Props> = ({isbn}) => {
    const [isLocationActive, setIsLocationActive] = useState<boolean>(localStorage.getItem('location') !== null)
    const [lat, long, locationName, isLocationLoading] = useCurrentLocation(isLocationActive)

    const makeLocationActive = () => {
        setIsLocationActive(true)
        localStorage.setItem('location', 'true')
    }

    const [settings] = useSearchSettings()
    const [librariesOffline, isLibraryOfflineLoading] = useBookLibraryOffline(isbn, settings, lat, long)

    const [subTitle, setSubTitle] = useState<string>()
    useEffect(() => {
        if (settings?.librarySearchMethod === 'SPECIFIC_REGIONS') {
            setSubTitle(settings.regionDetails.map((region) => region.depth2.koreanName).join(', '))
        } else if (settings?.librarySearchMethod === 'SPECIFIC_LIBRARIES') {
            setSubTitle(settings.libraries.map((library) => library.name.replace(/\s*\([^)]*\)/g, "")).join(', '))
        } else {
            setSubTitle(locationName ?? '?')
        }
    }, [settings, isLocationLoading]);

    return (
        <BookSourceSection
            title={`도서관`}

            // @ts-ignore
            subTitle={
                (settings?.librarySearchMethod === 'CURRENT_LOCATION' || settings == null) && isLocationLoading ?
                    <LoadingBar size={5}/>
                    :
                    subTitle
            }

            right={
                <a href={'/settings/search'}>
                    <h3 className={'text-book'}>
                        <BooksitoutIcon.settings/>
                    </h3>
                </a>
            }

            isEmpty={isLibraryOfflineLoading ? false : librariesOffline.length === 0}

            // @ts-ignore
            body={
                !isLocationActive && settings?.librarySearchMethod === 'CURRENT_LOCATION' ?
                    <LocationRequestCard requestLocation={() => makeLocationActive()}/>
                    :
                    isLocationLoading && settings?.librarySearchMethod === 'CURRENT_LOCATION' ?
                        <>
                            <RowSpacer size={10}/>
                            <BookSourceSectionLoading message={'위치 정보를 가져오고 있어요'}/>
                        </>
                        :
                        isLibraryOfflineLoading ?
                            <>
                                <RowSpacer size={10}/>
                                <BookSourceSectionLoading/>
                            </>
                            :
                            <Body librariesOffline={librariesOffline}/>
            }
        />
    )
}

interface LocationRequestCardProps {
    requestLocation: () => void
}

const LocationRequestCard: React.FC<LocationRequestCardProps> = ({requestLocation}) => {
    return (
        <LocationContainer>
            <RowSpacer size={20}/>
            <Button onClick={requestLocation} variant={'book'}>위치 허용하고 근처 도서관에서 검색하기</Button>
            <RowSpacer size={10}/>
            <LocationDisclaimer>위치 정보는 저장하지 않아요. 자세한건 <LocationDisclaimerLink
                href={"https://booksitout.com/privacy/2023-07-01?language=KOREAN"}>개인정보처리방침</LocationDisclaimerLink>을
                참고해 주세요.</LocationDisclaimer>
        </LocationContainer>
    )
}

interface BodyProps {
    librariesOffline: SearchBookSourceLibraryOfflineResponse[]
}

const Body: React.FC<BodyProps> = ({librariesOffline}) => {
    if (librariesOffline.length == 0) {
        return <BookSourceNoContent message={'도서관에는 책이 없어요'}/>
    }

    return (
        <>
            {
                librariesOffline
                    .map(library => {
                        return (
                            <div className={'hover-effect w-100'}>
                                <a href={library.link!!} target={'_blank'} className={'w-100'}>
                                    <RowSpacer size={10}/>
                                    <Container>
                                        <div>
                                        <span className={'d-flex'}>
                                            {
                                                library.library.logo &&
                                                <>
                                                    <Icon src={library.library.logo}/>
                                                    <ColSpacer size={5}/>
                                                </>
                                            }
                                            <h6 className={'p-0 m-0 pt-1'}>{library.library.name}</h6>
                                        </span>

                                            <RowSpacer size={5}/>

                                            <span className={'d-flex'}>
                                            <LoanPossibleBadge isLoanPossible={library.loanPossible}/>
                                                {
                                                    library.reservationPossible != null && library.loanPossible == false &&
                                                    <>
                                                        <ColSpacer size={10}/>
                                                        <LoanPossibleBadge isLoanPossible={library.reservationPossible}
                                                                           possibleMessage={'예약가능'}
                                                                           impossibleMessage={'예약불가'}/>
                                                    </>
                                                }
                                        </span>
                                        </div>

                                        <BookLocationContainer>
                                            <BookLocationText>{library.bookLocation ?? '?'}</BookLocationText>
                                        </BookLocationContainer>
                                    </Container>
                                </a>
                            </div>
                        )
                    })
            }
        </>
    )
}

const Icon = styled.img`
    width: 30px;
    height: 30px;
`

const Container = styled(ContentContainer)`
    flex-direction: column;
    justify-content: space-between;
`

const BookLocationContainer = styled.div.attrs({
    className: 'force-1-line'
})`
    height: 100%;
    min-width: 100px;
    text-align: right;

    display: grid;
    place-items: flex-end;
`

const BookLocationText = styled.h6.attrs({
    className: 'text-secondary force-1-line'
})`
    padding: 0;
    margin: 0;

    font-size: 0.9rem;
    @media (max-width: ${breakpoints.md}) {
        font-size: 0.8rem;
    }
`

const LocationContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
`

const LocationDisclaimer = styled.p`
    font-size: 0.8rem;
    color: #6c757d;
    text-align: center;
`

const LocationDisclaimerLink = styled.a`
    color: ${ColorConfig.Primary};
    font-weight: bold;
`

export default BookSourceOfflineLibrarySection
