import React, {useEffect, useState} from "react";
import styled from 'styled-components';
import {Card, Placeholder} from 'react-bootstrap';
import ColSpacer from '../../../common/styles/ColSpacer';
import useBookGoalStore from "./useBookGoalStore";
import BookGoalResponse from "./BookGoalResponse";
import BooksitoutImages from "../../../images/BooksitoutImages";

interface Props {
    size?: number
    year?: number
    isLoading: boolean
}

const BookGoalCard: React.FC<Props> = ({size = 75, year = new Date().getFullYear(), isLoading}) => {
    const {goals, getGoal} = useBookGoalStore()

    const [goal, setGoal] = useState<BookGoalResponse | null>(null)
    const [isDone, setIsDone] = useState<boolean>(false)
    useEffect(() => {
        let goalForYear = getGoal(year)
        setGoal(goalForYear)
        setIsDone((goalForYear?.current ?? -2) >= (goalForYear?.total ?? -1))
    }, [goals]);

    return (
        <Container>
            {
                isDone && (
                    <GoalCompleteContainer>
                        <ImageGoalDone src={BooksitoutImages.Statistics.goalComplete} alt=''/>
                        <GoalCompleteText>목표달성!</GoalCompleteText>
                    </GoalCompleteContainer>
                )
            }

            <GoalContainer>
                <GoalImageContainer isDone={isDone}>
                    <Image src={BooksitoutImages.Statistics.goal} alt='' size={size}/>
                </GoalImageContainer>

                <ColSpacer size={10}/>

                <GoalTextContainer isDone={isDone}>
                    <GoalText size={75}>
                        {
                            isLoading ? (
                                <Placeholder as={Card.Text} animation='wave'>
                                    <Placeholder xs='2'/> 권
                                    <span> / </span>
                                    <Placeholder xs='2'/> 권
                                </Placeholder>
                            ) : goal == null ? <span className={'text-secondary'}>목표 없음</span> :
                                (
                                    <>
                                        <span className='text-book' style={{fontWeight: 'bold'}}>
                                            {goal.current}
                                        </span>
                                        권 / {goal.total}권
                                    </>
                                )
                        }
                    </GoalText>
                </GoalTextContainer>
            </GoalContainer>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    width: 100%;
    margin: 0;
`;

const GoalContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    text-align: end;
    width: 100%;
`;

const GoalCompleteContainer = styled.div`
    position: absolute;
    z-index: 1;
    opacity: 100;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
`;

const ImageGoalDone = styled.img.attrs({
    className: 'img-fluid'
})`
    height: 50px;
    text-align: center;
`;

const GoalCompleteText = styled.h5`
    font-weight: bold;
    margin-top: 5px;
`;

interface IsDoneProps {
    isDone: boolean
}

const GoalImageContainer = styled.div<IsDoneProps>`
    opacity: ${({isDone}) => (isDone ? '0.1' : '1.0')}
`;

const GoalTextContainer = styled.div<IsDoneProps>`
    align-self: center;
    opacity: ${({isDone}) => (isDone ? '0.1' : '1.0')}
`;

interface SizeProps {
    size: number
}

const Image = styled.img.attrs({
    className: 'img-fluid'
})<SizeProps>`
    height: ${props => props.size}px;
`;

const GoalText = styled.h1.attrs({
    className: 'force-1-line'
})<SizeProps>`
    font-size: ${props => props.size / 2}px;
    margin: 0;
`;

export default BookGoalCard