import { useEffect, useState } from "react"
import LocationFullResponse from "./LocationFullResponse"
import { BooksitoutServer } from "../../config/BooksitoutServer"

const useLocationRegion = (region: string | undefined, regionDetail: string | undefined | null) => {
    const [location, setLocation] = useState<LocationFullResponse | null>(null)

    useEffect(() => {
        if (region) {
            BooksitoutServer
                .get(`/v1/location/by-english-name?region-english-name=${region}${regionDetail ? `&region-detail-english-name=${regionDetail}` : ''}`)
                .then((res) => setLocation(res.data))
        }
    }, [region])

    return location
}

export default useLocationRegion