import {useEffect, useState} from "react"
import LibraryMembershipResponse from "./membership/LibraryMembershipResponse"
import {BooksitoutServer} from "../../config/BooksitoutServer"
import {useNavigate} from "react-router-dom"
import toast from "react-hot-toast"

const useLibraryMembership = (libraryId: number) => {
    const navigate = useNavigate()

    const [membership, setMembership] = useState<LibraryMembershipResponse | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isNotFound, setIsNotFound] = useState<boolean>(false)
    
    useEffect(() => {
        BooksitoutServer
            .get(`/v1/library/membership/${libraryId}`)
            .then((res) => {
                if (res.status === 204) throw new Error('Not Found')

                setMembership(res.data)
            })
            .catch(() => setIsNotFound(true))
            .finally(() => setIsLoading(false))
    }, [libraryId])

    const deleteMembership = () => {
        if (window.confirm('정말 도서관 회원증을 삭제할까요?')) {
            BooksitoutServer
                .delete(`v1/library/membership/${libraryId}`)
                .then(() => {
                    toast.success('회원증을 삭제했어요')
                    navigate('/library/membership')
                })
                .catch(() => toast.error('오류가 났어요. 잠시 후 다시 시도해 주세요'))
        }
    }

    return [membership, isLoading, isNotFound, deleteMembership] as const
}

export default useLibraryMembership