import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {Button, Form} from 'react-bootstrap'
import {BooksitoutServer} from '../../config/BooksitoutServer'
import RouteContainer from '../../common/styles/RouteContainer';
import TipsResponse from '../community/tips/TipsResponse';
import RowSpacer from "../../common/styles/RowSpacer";
import TipsContentCard from "../community/tips/TipsContentCard";

const AdminTipsRoute = () => {
    const {tipsId} = useParams()

    const [tip, setTip] = useState<TipsResponse | null>(null)
    useEffect(() => {
        BooksitoutServer
            .get(`/v1/tips/admin/${tipsId}`)
            .then((res) => {
                setTip(res.data)
                setTitle(res.data.title)
                setContent(res.data.content)
                setSummary(res.data.summary)
                setImageUrl(res.data.displayImageUrl)
                setIsPublic(res.data.isPublic)
            })
    }, [tipsId])

    const [title, setTitle] = useState<string>('')
    const [content, setContent] = useState<string>('')
    const [summary, setSummary] = useState<string>('')
    const [imageUrl, setImageUrl] = useState<string>('')
    const [isPublic, setIsPublic] = useState<boolean>(false)

    useEffect(() => {
        setTip((prevTip) => ({
            ...(prevTip as TipsResponse),
            title: title,
            content: content,
            summary: summary,
            displayImageUrl: imageUrl,
            isPublic: isPublic,
        }))
    }, [title, content, summary, imageUrl, isPublic]);

    const edit = () => {
        BooksitoutServer
            .put(`/v1/tips/admin/${tipsId}`, {
                title: title,
                content: content,
                summary: summary,
                displayImageUrl: imageUrl,
                isPublic: isPublic
            })
            .then(() => alert('수정되었습니다.'))
    }

    if (tip == null) return <></>

    return (
        <RouteContainer>
            <RowSpacer/>

            <div className="row justify-content-center">
                <div className={'col-12 col-md-6'}>
                    <RowSpacer/>
                    <Form.Control value={title} size='lg' onChange={(e) => setTitle(e.target.value)}
                                  placeholder='title'/>

                    <RowSpacer/>
                    <Form.Control value={imageUrl} size='lg' onChange={(e) => setImageUrl(e.target.value)}
                                  placeholder='image url'/>

                    <RowSpacer/>
                    <Form.Control value={summary} as='textarea' rows={5} onChange={((e) => setSummary(e.target.value))}
                                  placeholder='summary'/>

                    <RowSpacer/>
                    <Form.Control value={content} as='textarea' rows={20} onChange={((e) => setContent(e.target.value))}
                                  placeholder='content'/>

                    <RowSpacer/>
                    <Form.Group className='mb-5' controlId='formBasicCheckbox'>
                        <Form.Check
                            type='switch'
                            label='해당 포스트 공개하기'
                            checked={isPublic}
                            onChange={() => setIsPublic(!isPublic)}
                            className='force-1-line'
                        />
                    </Form.Group>

                    <RowSpacer/>
                    <Button variant='book' onClick={() => edit()}>수정하기</Button>
                </div>

                <div className={'col-12 col-md-6'}>
                    <TipsContentCard tip={tip}/>
                </div>
            </div>
        </RouteContainer>
    )
}

export default AdminTipsRoute