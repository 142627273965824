import styled from 'styled-components';
import RouteContainer from "../../../common/styles/RouteContainer"
import RouteTitle from "../../../common/RouteTitle/RouteTitle"
import booksitoutIcon from "../../../config/BooksitoutIcon"
import RouteTitleConfig from "../../../config/RouteTitleConfig"
import RowSpacer from '../../../common/styles/RowSpacer';
import useHtmlTitle from "../../../common/useHtmlTitle";
import {usePopularBooks} from "./usePopularBooks";
import PopularBookListRowLoading from "./PopularBookListRowLoading";
import PopularBookListRow from "./PopularBookListRow";
import CardTitle from "../../../common/styles/CardTitle";
import BooksitoutLogo from "../../../common/BooksitoutLogo";

const PopularBookRoute = () => {
    useHtmlTitle('인기책 | 책잇아웃')

    const [isLoading, popularBooks] = usePopularBooks(100)

    return (
        <RouteContainer>
            <RouteTitle
                icon={<booksitoutIcon.community/>}
                title={'인기책'}
                subTitle={'지금 책잇아웃에서 인기 있는 책을 만나보세요'}
                currentKey={'popular'}
                buttons={RouteTitleConfig.Community}
                rightUi={undefined}
            />
            <RowSpacer/>

            <TitleContainer>
                <CardTitle
                    icon={<BooksitoutLogo/>}
                    title='책잇아웃 베스트셀러'
                    url=''
                />
            </TitleContainer>
            <RowSpacer/>

            <Row>
                {
                    isLoading ?
                        Array(100).fill(0).map((_, index) => index + 1).map(index =>
                            <Col>
                                <PopularBookListRowLoading index={index}/>
                            </Col>
                        )
                        :
                        popularBooks.slice(0, 100).map(book =>
                            <Col>
                                <PopularBookListRow popularBook={book}/>
                            </Col>
                        )
                }
            </Row>
            <RowSpacer/>
        </RouteContainer>
    )
}

const Row = styled.div.attrs({
    className: 'row'
})`
`;

const Col = styled.div.attrs({
    className: 'col-12'
})`
`;

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default PopularBookRoute