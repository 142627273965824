import {useEffect, useState} from "react"
import TipsResponse from "./TipsResponse"
import {BooksitoutServer} from "../../../config/BooksitoutServer"

const useTips = (tipsId: number | null) => {
    const [tips, setTips] = useState<TipsResponse | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        if (tipsId != null) {
            BooksitoutServer
                .get(`/v1/tips/${tipsId}`)
                .then((res) => setTips(res.data))
                .finally(() => setIsLoading(false));
        }
    }, [tipsId])

    return [tips, isLoading] as const
}

export default useTips