import {useEffect, useState} from "react"
import BookGoalResponse from "./BookGoalResponse"
import {BooksitoutServer} from "../../../config/BooksitoutServer"
import useBookGoalStore from "./useBookGoalStore";

const useBookGoal = (year: number) => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [goal, setGoal] = useState<BookGoalResponse | null>(null)
    const [isDone, setIsDone] = useState<boolean>(false)

    const {getGoal} = useBookGoalStore()

    useEffect(() => {
        let goalFromStore = getGoal(year)
        if (goalFromStore) {
            setGoal(goalFromStore)
            setIsDone(goalFromStore.current >= goalFromStore.total && goalFromStore.total !== 0)
            setIsLoading(false)
        } else {
            BooksitoutServer
                .get(`/v1/book/goals/${year}`)
                .then((res) => {
                    if (res.status != 204) {
                        setGoal(res.data)
                    }

                    setIsDone(res.data.current >= res.data.total && res.data.total !== 0)
                })
                .finally(() => setIsLoading(false))
        }
    }, [year])

    return [goal, isDone, isLoading] as const
}

export default useBookGoal