import React from "react";
import styled from "styled-components";
import ColSpacer from "../../../common/styles/ColSpacer";
import BooksitoutImages from "../../../images/BooksitoutImages";
import LoadingBar from "../../../common/LoadingBar";
import ColorConfig from "../../../config/ColorConfig";
import utils from "../../../common/utils";

interface Props {
    streakCount: number | null
    isTodayDone: boolean
    isLoading: boolean
}

const BookStreakCard: React.FC<Props> = ({streakCount, isTodayDone, isLoading}) => {
    return (
        <Container>
            <StreakContainer isActive={isTodayDone}>
                <div>
                    <Image src={BooksitoutImages.Statistics.streak} alt='' size={75}/>
                </div>

                <ColSpacer size={10}/>

                <TextContainer>
                    <Text size={75}>
                        {
                            isLoading ? (
                                <div className={'d-flex'}>
                                    <LoadingBar size={3}/> 일
                                </div>
                            ) : (
                                <div className={'d-flex'}>
                                    <StreakCountText>{utils.insertCommas(streakCount ?? 0)}</StreakCountText> 일
                                </div>
                            )
                        }
                    </Text>
                </TextContainer>
            </StreakContainer>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    width: 100%;
    margin: 0;
    height: 100%;
`;

interface StreakProps {
    isActive: boolean
}

const StreakContainer = styled.div<StreakProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    text-align: end;
    width: 100%;

    opacity: ${props => props.isActive ? 1 : 0.5};
`;

const TextContainer = styled.div`
    align-self: center;
`;

interface SizeProps {
    size: number
}

const Image = styled.img.attrs({
    className: 'img-fluid'
})<SizeProps>`
    height: ${props => props.size}px;
`;

const Text = styled.h1.attrs({
    className: 'force-1-line'
})<SizeProps>`
    font-size: ${props => props.size / 2}px;
    margin: 0;
`;

const StreakCountText = styled.span`
    padding-right: 5px;
    color: ${ColorConfig.Primary};
    font-weight: bold;
`

export default BookStreakCard
