import styled from 'styled-components';
import RouteContainer from '../../../common/styles/RouteContainer'
import RouteTitle from '../../../common/RouteTitle/RouteTitle'
import booksitoutIcon from '../../../config/BooksitoutIcon'
import {Card} from 'react-bootstrap'
import CardBodyContentContainer from '../../../common/styles/CardBodyContentContainer'
import BookStatisticsByYearCard from './cards/BookStatisticsByYearCard'
import RowSpacer from '../../../common/styles/RowSpacer'
import BookStatisticsReadTimeCard from './cards/BookStatisticsReadTimeCard';
import RouteTitleConfig from '../../../config/RouteTitleConfig';
import useLoginStore from '../../login/useLoginStore';
import Login from '../../../common/Login';
import useHtmlTitle from "../../../common/useHtmlTitle";
import BookStatisticsCategoryCard from "./cards/BookStatisticsCategoryCard";
import BookStatisticsLanguageCard from "./cards/BookStatisticsLanguageCard";
import BookGoalCard from "../goal/BookGoalCard";
import useBookGoal from "../goal/useBookGoal";
import {useEffect} from "react";
import useBookGoalStore from "../goal/useBookGoalStore";
import IndexContentContainer from "../../index/IndexContentContainer";
import BookStreakCard from "../streak/BookStreakCard";
import useBookStreak from "../streak/useBookStreak";

const BookStatisticsRoute = () => {
    useHtmlTitle('통계 | 책잇아웃')

    const isLoggedIn = useLoginStore((state) => state.isLoggedIn())

    return (
        <RouteContainer>
            <RouteTitle
                icon={<booksitoutIcon.statistics/>}
                title={'통계'}
                subTitle={'내 독서활동에 대한 통계를 확인할 수 있어요'}
                currentKey={'statistics'}
                buttons={RouteTitleConfig.Book}
                rightUi={undefined}
            />

            {isLoggedIn ? <YesLoggedInCase/> : <NoLoggedInCase/>}
            <RowSpacer/>
        </RouteContainer>
    )
}

const YesLoggedInCase = () => {
    const {init} = useBookGoalStore()
    const [goal, isDone, isGoalLoading] = useBookGoal(new Date().getFullYear())
    useEffect(() => {
        init(goal ? [goal] : [])
    }, [goal]);

    const [streakCount, isTodayDone, isStreakLoading] = useBookStreak()

    return (
        <>
            <RowSpacer/>
            <Row>
                <ColTop>
                    <CardBodyContentContainer height={100} href={'/book/goal'}>
                        <BookGoalCard isLoading={isGoalLoading}/>
                    </CardBodyContentContainer>
                    <RowSpacer size={15}/>
                </ColTop>

                <ColTop>
                    <CardBodyContentContainer height={100}>
                        <BookStreakCard streakCount={streakCount} isTodayDone={isTodayDone} isLoading={isStreakLoading}/>
                    </CardBodyContentContainer>
                    <RowSpacer size={15}/>
                </ColTop>

                <ColChart>
                    <Card>
                        <CardBodyContentContainer>
                            <BookStatisticsReadTimeCard/>
                        </CardBodyContentContainer>
                    </Card>
                    <RowSpacer size={15}/>
                </ColChart>

                <ColSummary>
                    <Card>
                        <CardBodyContentContainer>
                            <BookStatisticsByYearCard/>
                        </CardBodyContentContainer>
                    </Card>
                    <RowSpacer size={15}/>
                </ColSummary>

                <ColSummary>
                    <Card>
                        <CardBodyContentContainer>
                            <BookStatisticsCategoryCard/>
                        </CardBodyContentContainer>
                    </Card>
                </ColSummary>

                <ColSummary>
                    <Card>
                        <CardBodyContentContainer>
                            <BookStatisticsLanguageCard/>
                        </CardBodyContentContainer>
                    </Card>
                </ColSummary>
            </Row>
        </>
    )
}

const NoLoggedInCase = () => {
    return (
        <>
            <RowSpacer size={40}/>
            <Login message={'로그인해 내 독서활동 통계 보기'}/>
        </>
    )
}

const Row = styled.div.attrs({
    className: 'row'
})`
`;

const ColChart = styled.div.attrs({
    className: 'col-12 col-md-6 col-xl-8'
})`
`;

const ColSummary = styled.div.attrs({
    className: 'col-12 col-md-6 col-xl-4'
})`
`;

const ColTop = styled.div.attrs({
    className: 'col-12 col-lg-6 col-xl-4'
})``

export default BookStatisticsRoute