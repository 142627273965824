import React from "react";
import utils from '../../../common/utils'
import useBookStatisticsSummary from './hooks/useBookStatisticsSummary'
import StatisticsTable from "../../../common/StatisticsTable";
import BooksitoutImages from "../../../images/BooksitoutImages";

interface Props {
    year: number
}

const BookStatisticsTable: React.FC<Props> = ({year}) => {
    const [statistics, isLoading] = useBookStatisticsSummary(year)

    const statisticsData = (statistics) => [
        {
            id: 1,
            icon: BooksitoutImages.Statistics.time,
            name: '총 독서시간',
            value: `${statistics.yearStatistics.totalReadTime != null && Math.round(statistics.yearStatistics.totalReadTime / 60)}시간`,
        },
        {
            id: 2,
            icon: BooksitoutImages.Statistics.average,
            name: '하루 평균',
            value: `${statistics.dayStatistics.averageReadTime != null && statistics.dayStatistics.averageReadTime}분`,
        },
        {
            id: 3,
            icon: BooksitoutImages.Statistics.book,
            name: '읽은 책',
            value: `${statistics.yearStatistics.totalReadBookCount != null && statistics.yearStatistics.totalReadBookCount}권`,
        },
        {
            id: 4,
            icon: BooksitoutImages.Statistics.star,
            name: '평균별점',
            value: `${statistics.yearStatistics.averageStar != null && statistics.yearStatistics.averageStar.toFixed(1)}점`,
        },
        {
            id: 5,
            icon: BooksitoutImages.Statistics.longestDay,
            name: '최대 독서 시간',
            value: `${statistics.dayStatistics.mostReadTime != null && statistics.dayStatistics.mostReadTime}분`,
        },
        {
            id: 6,
            icon: BooksitoutImages.Statistics.page,
            name: '총 읽은 페이지',
            value: `${statistics.yearStatistics.totalReadPage != null && utils.insertCommas(statistics.yearStatistics.totalReadPage)}`,
        },
    ]

    return (
        // @ts-ignore
        <StatisticsTable isLoading={isLoading} rows={statisticsData(statistics)}/>
    )
}

export default BookStatisticsTable
