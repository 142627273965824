import React from "react";
import Modal from "../../../../common/Modal";
import BookMemoResponse from "./BookMemoResponse";
import styled from "styled-components";
import ColorConfig from "../../../../config/ColorConfig";
import parse from 'html-react-parser';

interface Props {
    isOpen: boolean
    close: () => void
    memos: BookMemoResponse[]
}

const BookDetailMemoAllModal: React.FC<Props> = ({isOpen, close, memos}) => {
    return (
        <Modal
            titleText={'✏️ 메모 전체 보기'}
            isShowing={isOpen}
            onClose={close}
            size={'lg'}
            body={
                <>
                    {
                        memos.map((memo) => (
                            <Memo key={memo.id}>
                                <PageNumber>{memo.page ?? '-'}</PageNumber>
                                <MemoContent>{parse(memo.content)}</MemoContent>
                            </Memo>
                        ))
                    }
                </>
            }
        />
    )
}

const Memo = styled.div.attrs({
    className: 'col-12'
})`
    background-color: #fff9c4;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
`;


const PageNumber = styled.div`
    font-size: 0.85em;
    font-weight: bold;
    margin-bottom: 5px;
    color: ${ColorConfig.Primary};
`;

const MemoContent = styled.div.attrs({})`
    font-size: 1em;
    color: #333;
    min-height: 50px;

    p {
        margin: 10px 0;
    }

    blockquote {
        border-left: 3px solid #f5c2c7;
        margin: 10px 0;
        padding: 10px 20px;
        background: #f5f5f5;
        font-style: normal;
        color: #333;
        font-size: 1em;
        border-radius: 4px;
    }

    ul {
        list-style: disc;
        margin: 5px 0;
        padding-left: 40px;
    }

    ul li {
        margin: 0;
        padding: 2px 0;
    }

    p:empty {
        display: none;
    }
`;

export default BookDetailMemoAllModal
