import React from "react";
import styled from 'styled-components';
import ColorConfig from '../../../config/ColorConfig';
import IndexContentContainer from '../../index/IndexContentContainer';
import ColSpacer from '../../../common/styles/ColSpacer';
import LoadingBar from '../../../common/LoadingBar';
import BooksitoutImages from "../../../images/BooksitoutImages";
import RowSpacer from "../../../common/styles/RowSpacer";

interface Props {
    index: number
}

const PopularBookListRowLoading: React.FC<Props> = ({index}) => {
    return (
        <IndexContentContainer target='_blank'>
            <div className={'w-100'}>
                <Row>
                    <RankingBadge>{index}</RankingBadge>
                    <ColSpacer size={2.5}/>

                    <CoverContainer>
                        <Cover src={BooksitoutImages.Placeholder.bookCoverLoading}/>
                    </CoverContainer>

                    <ColSpacer size={5}/>

                    <InfoContainer>
                        <Title>
                            <LoadingBar size={6.5}/>
                        </Title>

                        <Author>
                            <LoadingBar size={4}/>
                        </Author>

                        <span className={'d-none d-sm-block'}>
                            <PopularBookSourcesSection/>
                    </span>
                    </InfoContainer>
                </Row>

                <RowSpacer size={10}/>

                <span className={'d-block d-sm-none'}>
                    <PopularBookSourcesSection/>
                </span>
            </div>
        </IndexContentContainer>

    )
}

const PopularBookSourcesSection = () => {
    return (
        <div className={'d-flex justify-content-center w-100'}>
            <div className={'d-flex justify-content-center align-items-center force-1-line'} style={{width: '75px'}}>
                <Logo src={BooksitoutImages.WebsiteLogo.aladin}/>
                <ColSpacer size={2.5}/>
                <p className={'d-flex align-items-center m-0'}><LoadingBar size={0.9}/>위</p>
            </div>
            <ColSpacer size={5}/>

            <div className={'d-flex justify-content-center align-items-center force-1-line'} style={{width: '75px'}}>
                <Logo src={BooksitoutImages.WebsiteLogo.yes24}/>
                <ColSpacer size={2.5}/>
                <p className={'d-flex align-items-center m-0'}><LoadingBar size={0.9}/>위</p>
            </div>
            <ColSpacer size={5}/>

            <div className={'d-flex justify-content-center align-items-center force-1-line'} style={{width: '75px'}}>
                <Logo src={BooksitoutImages.WebsiteLogo.kyobo}/>
                <ColSpacer size={2.5}/>
                <p className={'d-flex align-items-center m-0'}><LoadingBar size={0.9}/>위</p>
            </div>
            <ColSpacer size={5}/>
        </div>
    )
}

const Row = styled.div`
    position: relative;
    display: flex;
    width: 100%;
`;

const CoverContainer = styled.div`
    min-width: 75px;
    max-width: 75px;
    justify-content: center;
    text-align: center;
`;

interface CoverProps {
    src: string | null
}

const Cover = styled.img.attrs({
    className: 'img-fluid rounded',
})<CoverProps>`
    height: 90px;
`;

const Title = styled.h5.attrs({
    className: 'clamp-1-line'
})`
`;

const Author = styled.h6.attrs({
    className: 'text-secondary clamp-1-line'
})`
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;

    max-width: calc(100% - 100px);
`;

const RankingBadge = styled.div`
    right: 0;
    min-width: 25px;
    height: 25px;
    background-color: ${ColorConfig.Primary};
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Logo = styled.img.attrs({
    className: 'rounded'
})`
    width: 25px;
    height: 25px;
`

export default PopularBookListRowLoading