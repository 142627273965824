import {useEffect, useState} from "react"
import {SearchBookSourceLibraryOfflineResponse} from "../SearchBookSourceLibraryResponse"
import {BooksitoutServer} from "../../../../config/BooksitoutServer"
import SearchSettingsResponse from "../../../appUsers/settings/response/BookSearchSettingsResponse";
import useLoginStore from "../../../login/useLoginStore";

const useBookLibraryOffline = (
    isbn13: string,
    settings: SearchSettingsResponse | null,
    lat: number | null,
    long: number | null
) => {
    const [books, setBooks] = useState<SearchBookSourceLibraryOfflineResponse[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const isLoggedIn = useLoginStore((state) => state.isLoggedIn())

    useEffect(() => {
        if (isLoggedIn && settings == null) {
            return
        }

        if (books.length !== 0) {
            return
        }

        const isIsbn13Valid = isbn13 != null && isbn13 !== '';
        const isLocationNotLoading = lat !== undefined && long !== undefined
        const isLocationValid = lat !== null && long !== null && lat !== 0 && long !== 0

        if (isIsbn13Valid && isLocationNotLoading && isLocationValid) {
            setIsLoading(true)

            BooksitoutServer
                .get(`/v1/book/search/sources/library/offline?isbn13=${isbn13}&lat=${lat}&long=${long}`)
                .then((res) => setBooks(res.data))
                .finally(() => setIsLoading(false))
        } else if (isIsbn13Valid && settings?.librarySearchMethod !== 'CURRENT_LOCATION') {
            setIsLoading(true)

            BooksitoutServer
                .get(`/v1/book/search/sources/library/offline?isbn13=${isbn13}`)
                .then((res) => setBooks(res.data))
                .finally(() => setIsLoading(false))
        } else {
            setIsLoading(false)
        }
    }, [isbn13, lat, long, settings])

    return [books, isLoading] as const
}

export default useBookLibraryOffline