import React from 'react'
import styled from 'styled-components';
import BookMemoResponse from './BookMemoResponse'
import {Button} from 'react-bootstrap'
import {BooksitoutServer} from '../../../../config/BooksitoutServer';
import toast from 'react-hot-toast';
import ColorConfig from "../../../../config/ColorConfig";
import Modal from '../../../../common/Modal';
import parse from 'html-react-parser';
import useBookDetailStore from "../useBookDetailStore";

interface Props {
    isOpen: boolean
    close: () => void
    memo: BookMemoResponse
    openEditModal: (memo: BookMemoResponse) => void
}

const BookDetailMemoDetailModal: React.FC<Props> = ({isOpen, close, memo, openEditModal}) => {
    return (
        <Modal
            titleText={'✏️ 메모 자세히 보기'}
            isShowing={isOpen}
            onClose={close}
            size={'lg'}
            body={
                <>
                    <PageNumber>{memo.page}</PageNumber>
                    <Content>{parse(memo.content.replaceAll("\n", "<br/>"))}</Content>

                    <ButtonContainer>
                        <EditButtonContainer>
                            <Button variant={'warning'} onClick={() => openEditModal(memo)}>수정</Button>
                        </EditButtonContainer>

                        <DeleteButtonContainer>
                            <DeleteButton memo={memo} close={close}/>
                        </DeleteButtonContainer>
                    </ButtonContainer>
                </>
            }
        />
    )
}

const DeleteButton = ({memo, close}) => {
    const {deleteMemo} = useBookDetailStore()
    const deleteMemoToServer = () => {
        if (!window.confirm('정말로 메모를 삭제할까요?')) return

        BooksitoutServer
            .delete(`/v1/book/memo/${memo.id}`)
            .then(() => {
                toast.success('메모를 삭제했어요.')

                if (memo != null) {
                    deleteMemo(memo.id);
                }

                close()
            })
            .catch(() => toast.error('오류가 났어요. 잠시 후 다시 시도해 주세요.'))
    }

    return (
        <Button variant='danger' onClick={deleteMemoToServer}>삭제</Button>
    )
}

const ButtonContainer = styled.div`
    display: flex;
    justify-content: right;
`

const DeleteButtonContainer = styled.div`
    margin-left: 10px;
`;

const EditButtonContainer = styled.div`
`;

const PageNumber = styled.div`
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: ${ColorConfig.Primary};
`;

const Content = styled.p`
    font-size: 1rem;
    min-height: 200px;

    p {
        margin: 10px 0;
    }

    blockquote {
        border-left: 3px solid #f5c2c7;
        margin: 10px 0;
        padding: 10px 20px;
        background: #f5f5f5;
        font-style: normal;
        color: #333;
        font-size: 1em;
        border-radius: 4px;
    }

    ul {
        list-style: disc;
        margin: 5px 0;
        padding-left: 40px;
    }

    ul li {
        margin: 0;
        padding: 2px 0;
    }

    p:empty {
        display: none;
    }
`;

export default BookDetailMemoDetailModal