import {useEffect, useState} from "react";
import {BooksitoutServer} from "../../../config/BooksitoutServer";

const useBookStreak = (username: string | undefined | null = null) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [streakCount, setStreakCount] = useState<number | null>(null)
    const [isTodayDone, setIsTodayDone] = useState<boolean>(true)

    useEffect(() => {
        if (username !== undefined) {
            BooksitoutServer
                .get(`/v1/book/statistics/streaks${username ? `?username=${username}` : ''}`)
                .then((res) => {
                    setStreakCount(res.data.streakCount)
                    setIsTodayDone(res.data.isTodayDone)
                })
                .finally(() => setIsLoading(false));
        }
    }, [username]);

    return [streakCount, isTodayDone, isLoading] as const
}

export default useBookStreak