import React from 'react'
import styled from 'styled-components';
import BookSourceSection from "./common/BookSourceSection";
import useBookLibraryOnline from "../hooks/useBookLibraryOnline";
import {SearchBookSourceLibraryOnlineResponse} from "../SearchBookSourceLibraryResponse";
import RowSpacer from "../../../../common/styles/RowSpacer";
import ContentContainer from "../../../../common/styles/ContentContainer";
import LoanPossibleBadge from "./common/LoanPossibleBadge";
import {getBookSourceIcon, getBookSourceName} from "../BookSource";
import ColSpacer from "../../../../common/styles/ColSpacer";
import BookSourceNoContent from "../BookSourceNoContent";
import BookSourceSectionLoading from './common/BookSourceSectionLoading';

interface Props {
    isbn: string
}

const BookSourceOnlineLibrarySection: React.FC<Props> = ({isbn}) => {
    const [librariesOnline, isLibraryOnlineLoading] = useBookLibraryOnline(isbn ?? '')

    return (
        <BookSourceSection
            title={`인터넷 도서관`}
            isEmpty={isLibraryOnlineLoading ? false : librariesOnline.length === 0}
            // @ts-ignore
            body={
                isLibraryOnlineLoading ?
                    <BookSourceSectionLoading/>
                    :
                    <Body librariesOnline={librariesOnline}/>
            }
        />
    )
}

interface BodyProps {
    librariesOnline: SearchBookSourceLibraryOnlineResponse[]
}

const Body: React.FC<BodyProps> = ({librariesOnline}) => {
    if (librariesOnline.length == 0) {
        return <BookSourceNoContent message={'인터넷 도서관에는 책이 없어요'}/>
    }

    return (
        <>
            {
                librariesOnline.map(library => {
                    return (
                        <div className={'hover-effect'}>
                            <a href={library.link!!} target={'_blank'}>
                                <RowSpacer size={10}/>
                                <ContentContainer>
                                    <div>
                                        <span className={'d-flex'}>
                                            <Icon src={getBookSourceIcon(library.provider)}/>
                                            <ColSpacer size={5}/>
                                            <h6 className={'p-0 m-0 pt-1'}>{getBookSourceName(library.provider)}</h6>
                                        </span>

                                        <LoanPossibleBadge isLoanPossible={library.loanPossible}/>
                                    </div>
                                </ContentContainer>
                            </a>
                        </div>
                    )
                })
            }
        </>
    );
}

const Icon = styled.img`
    width: 30px;
    height: 30px;
`

export default BookSourceOnlineLibrarySection
