import React from "react";
import styled from 'styled-components';
import PopularBookResponse from './PopularBookResponse'
import IndexContentContainer from '../../index/IndexContentContainer';
import ColSpacer from '../../../common/styles/ColSpacer';
import ColorConfig from '../../../config/ColorConfig';
import BooksitoutIcon from "../../../config/BooksitoutIcon";
import BooksitoutImages from "../../../images/BooksitoutImages";
import RowSpacer from "../../../common/styles/RowSpacer";

interface Props {
    popularBook: PopularBookResponse
}

const PopularBookListRow: React.FC<Props> = ({popularBook}) => {
    return (
        <IndexContentContainer href={`/book/${popularBook.isbn}?q=${popularBook.title}`}
                               style={{opacity: popularBook.isAdded ? '0.5' : '1.0'}}>
            {
                popularBook.isAdded &&
                (
                    <AddButtonContainer>
                        <h1 className={'text-book'}>
                            <BooksitoutIcon.check/>
                        </h1>
                    </AddButtonContainer>
                )
            }

            <div className={'w-100'}>
                <Row>
                    <RankingBadge>{popularBook.ranking}</RankingBadge>
                    <ColSpacer size={2.5}/>

                    <CoverContainer>
                        <Cover src={popularBook.coverUrl}/>
                    </CoverContainer>

                    <ColSpacer size={5}/>

                    <InfoContainer>
                        <Title>{popularBook.title}</Title>
                        <Author>{popularBook.author.substring(0, 20)}</Author>

                        <span className={'d-none d-sm-block'}>
                            <PopularBookSourcesSection popularBook={popularBook} isStart={true}/>
                        </span>
                    </InfoContainer>
                </Row>

                <RowSpacer size={10}/>

                <span className={'d-block d-sm-none'}>
                    <PopularBookSourcesSection popularBook={popularBook} isStart={false}/>
                </span>
            </div>
        </IndexContentContainer>
    )
}

const PopularBookSourcesSection = ({popularBook, isStart}) => {
    return (
        <div className={`d-flex ${isStart ? `justify-content-start` : `justify-content-center`} w-100`}>
            <div className={'d-flex justify-content-center align-items-center force-1-line'} style={{width: '70px'}}>
                <Logo src={BooksitoutImages.WebsiteLogo.aladin}/>
                <ColSpacer size={2.5}/>
                <p className={'m-0'}>{popularBook.otherRankings.aladin ?? '?'}위</p>
            </div>
            <ColSpacer size={5}/>

            <div className={'d-flex justify-content-center align-items-center force-1-line'} style={{width: '70px'}}>
                <Logo src={BooksitoutImages.WebsiteLogo.yes24}/>
                <ColSpacer size={2.5}/>
                <p className={'m-0'}>{popularBook.otherRankings.yes24 ?? '?'}위</p>
            </div>
            <ColSpacer size={5}/>

            <div className={'d-flex justify-content-center align-items-center force-1-line'} style={{width: '70px'}}>
                <Logo src={BooksitoutImages.WebsiteLogo.kyobo}/>
                <ColSpacer size={2.5}/>
                <p className={'m-0'}>{popularBook.otherRankings.kyobo ?? '?'}위</p>
            </div>
            <ColSpacer size={5}/>
        </div>
    )
}

const Row = styled.div`
    position: relative;
    display: flex;
    width: 100%;
`;

const CoverContainer = styled.div`
    min-width: 75px;
    max-width: 75px;
    justify-content: center;
    text-align: center;
`;

interface CoverProps {
    src: string | null
}

const Cover = styled.img.attrs({
    className: 'img-fluid rounded',
})<CoverProps>`
    height: 90px;
`;

const Title = styled.h5.attrs({
    className: 'clamp-1-line'
})`
`;

const Author = styled.h6.attrs({
    className: 'text-secondary clamp-1-line'
})`
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;

    max-width: calc(100% - 100px);
`;

const RankingBadge = styled.div`
    right: 0;
    min-width: 25px;
    height: 25px;
    background-color: ${ColorConfig.Primary};
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const AddButtonContainer = styled.div`
    position: absolute;
    right: 10px;
    top: 5px;
`

const Logo = styled.img.attrs({
    className: 'rounded'
})`
    width: 25px;
    height: 25px;
`

export default PopularBookListRow
