import React from 'react'
import styled from 'styled-components';
import {Card} from "react-bootstrap";
import CardBodyContentContainer from "../../../common/styles/CardBodyContentContainer";
import ColSpacer from "../../../common/styles/ColSpacer";
import AppUserResponse from "../AppUserResponse";
import BooksitoutImages from "../../../images/BooksitoutImages";
import BooksitoutIcon from "../../../config/BooksitoutIcon";
import Spacer from '../../../common/Spacer';
import BookStreakCard from "../../book/streak/BookStreakCard";
import useBookStreak from "../../book/streak/useBookStreak";
import RowSpacer from "../../../common/styles/RowSpacer";

interface Props {
    user: AppUserResponse
}

const ProfileUserCard: React.FC<Props> = ({user}) => {
    const [streakCount, isTodayDone, isStreakLoading] = useBookStreak(user.urlName)

    return (
        <div className={'row'}>
            <div className={'col-12 col-md-8 col-xl-9'}>
                <a href={`/users/${user.id}`}>
                    <Card>
                        <CardBodyContentContainer>
                            <ProfileContainer>
                                <Flex>
                                    {
                                        user.profileImageUrl != null
                                            ?
                                            <ProfileImage src={user.profileImageUrl}
                                                          alt={user.name}/>
                                            :
                                            <ProfileImage src={BooksitoutImages.Placeholder.user} alt={user.name}/>
                                    }

                                    <ColSpacer/>

                                    <div>
                                        <ProfileName>{user.name}</ProfileName>

                                        <ProfileDate>
                                            {user.registeredDate[0]}년
                                            {' '}
                                            {user.registeredDate[1]}월
                                            {' '}
                                            {user.registeredDate[2]}일
                                        </ProfileDate>
                                    </div>
                                </Flex>

                                <Spacer/>

                                {
                                    user.isMe &&
                                    <a href={'/settings/profiles'}>
                                        <h3 className={'text-book'}>
                                            <BooksitoutIcon.settings/>
                                        </h3>
                                    </a>
                                }
                            </ProfileContainer>
                        </CardBodyContentContainer>
                    </Card>
                </a>
            </div>

            <div className={'col-12 col-md-4 col-xl-3'}>
                <span className={'d-md-none'}>
                <RowSpacer/>
                </span>

                <Card className={'h-100'}>
                    <CardBodyContentContainer>
                        <BookStreakCard streakCount={streakCount} isTodayDone={isTodayDone}
                                        isLoading={isStreakLoading}/>
                    </CardBodyContentContainer>
                </Card>
            </div>
        </div>
    )
}

const Flex = styled.div`
    display: flex;
`

const ProfileContainer = styled.div`
    display: flex;
`

const ProfileName = styled.h1`
    font-size: 1.5rem;
    font-weight: bold;
`

const ProfileDate = styled.h1.attrs({
    className: 'text-secondary'
})`
    font-size: 1rem;
`

const ProfileImage = styled.img.attrs({
    className: 'rounded'
})`
    width: 80px;
    height: 80px;
`

export default ProfileUserCard
