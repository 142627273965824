import React, {useState} from 'react'
import Modal from "../../common/Modal";
import BooksitoutButton from "../../common/button/BooksitoutButton";
import {Form} from 'react-bootstrap';
import TextAreaInput from "../../common/form/TextAreaInput";
import RowSpacer from "../../common/styles/RowSpacer";
import {BooksitoutServer} from "../../config/BooksitoutServer";
import toast from "react-hot-toast";
import useFeedbackStore from "./useFeedbackStore";

const FeedbackModal = () => {
    const [content, setContent] = useState<string>('')
    const {isOpen, close} = useFeedbackStore()

    const onSubmit = (e) => {
        e.preventDefault()

        BooksitoutServer
            .post(`/v1/feedback`, {content: content})
            .then((res) => {
                if (res.status === 201) {
                    toast.success('피드벡이 성공적으로 전달됐어요. 감사합니다!')
                    setContent('')
                    close()
                } else {
                    throw new Error()
                }
            })
            .catch(() => toast.error('오류가 났어요. 잠시 후 다시 시도해 주세요.'))
    }

    return (
        <Modal titleText={`책잇아웃에 대한 생각 남기기`} isShowing={isOpen} onClose={close} size={'xl'} isPreventClose={true} body={
            <div>
                <Form onSubmit={(e) => onSubmit(e)}>
                    <TextAreaInput
                        isFocusOnAppear={true}
                        height={300}
                        placeholder={'책잇아웃에 관한 생각, 있으면 좋을 기능 등을 자유롭게 남겨 주세요.'}
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                    />

                    <RowSpacer size={10}/>

                    <BooksitoutButton message={`보내기`}/>
                </Form>
            </div>
        }/>
    )
}

export default FeedbackModal
