import {useEffect, useState} from "react"
import {BooksitoutServer} from "../../config/BooksitoutServer"
import LibraryResponse from "./near/LibraryNearResponse"

const useLibraryByRegion = (region: string | undefined, regionDetail: string | undefined | null) => {
    const [libraries, setLibraries] = useState<LibraryResponse[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        if (region != null) {
            BooksitoutServer
                .get(`/v1/library/by-region?region-english-name=${region}${regionDetail ? `&region-detail-english-name=${regionDetail}` : ''}`)
                .then((res) => setLibraries(res.data))
                .finally(() => setIsLoading(false));
        }
    }, [region, regionDetail])

    return [libraries, isLoading] as const
}

export default useLibraryByRegion