import styled from 'styled-components';
import RouteContainer from './styles/RouteContainer';
import RowSpacer from "./styles/RowSpacer";
import ColorConfig from "../config/ColorConfig";
import getLanguageValue from "./language/LanguageInfo";
import useLanguage from "./language/useLanguage";
import BooksitoutImages from "../images/BooksitoutImages";

const Footer = () => {
    const language = useLanguage()

	let CORE_VALUE = getLanguageValue(`BooksitoutValuesRoute.RouteTitle`, language ?? 'ENGLISH')
	let SERVICES = getLanguageValue(`Footer.Services`, language ?? 'ENGLISH')
	let PRIVACY = getLanguageValue(`Footer.Privacy`, language ?? 'ENGLISH')
	let BUSINESS_REGISTRATION_NUMBER = getLanguageValue(`Footer.BusinessRegistrationNumber`, language ?? 'ENGLISH')
	let JINKYUNMPARK = getLanguageValue(`Footer.JinkyumPark`, language ?? 'ENGLISH')

	return (
		<RouteContainer>
			<Container>
				<ServiceName>
					<ServiceLogo>
						<img src={BooksitoutImages.WebsiteLogo.booksitout} alt="" className="img-fluid" />
					</ServiceLogo>

					<ServiceText>책잇아웃 (booksitout)</ServiceText>
				</ServiceName>

				<InfoContainer>
					<Info>{BUSINESS_REGISTRATION_NUMBER} 595-64-00704 | {JINKYUNMPARK}</Info>
					{/* <Info>통신판매업 신고번호 2023-서울강남-00000</Info> */}
					<InfoLink href={`/privacy/2023-07-01?language=${language}`}>{PRIVACY}</InfoLink>
					<InfoLink href={`/products?language=${language}`}>{SERVICES}</InfoLink>
					<InfoLink href={`/values?language=${language}`}>{CORE_VALUE}</InfoLink>
					<InfoLink href='mailto:booksitout@gmail.com'>booksitout@gmail.com</InfoLink>
				</InfoContainer>

				<LinkContainer>
					<Link href={'https://apps.apple.com/app/pianoman/id6456449788'}>
						<Image src={BooksitoutImages.WebsiteLogo.pianoman} alt={''} className={'border'}/>
					</Link>

					<Link href={'https://apps.apple.com/us/app/featured-days/id6523433258'}>
						<Image src={BooksitoutImages.WebsiteLogo.featuredDays} alt={''}/>
					</Link>

					<Link href={'https://apps.apple.com/app/purrfit-tracker/id6458539987'}>
						<Image src={BooksitoutImages.WebsiteLogo.purrfitTracker} alt={''}/>
					</Link>

					<Link href={'https://apps.apple.com/app/substrack/id6451234943'}>
						<Image src={BooksitoutImages.WebsiteLogo.substrack} alt={''}/>
					</Link>
				</LinkContainer>

				<RowSpacer size={40}/>
			</Container>
		</RouteContainer>
	)
}

const Container = styled.div.attrs({
	className: 'container-fluid',
})`
	max-width: 1920px;
	overflow-x: hidden;
	overflow-y: hidden;

	justify-content: left;
	text-align: left;

	padding-left: 0;
	padding-right: 0;
`

const ServiceName = styled.div.attrs({
	className: '',
})`
    display: flex;
    align-items: center;
    justify-content: left;
`

const ServiceLogo = styled.div.attrs({
	className: '',
})`
    width: 25px;
    height: 25px;
`

const ServiceText = styled.h6`
    margin-left: 10px;
    padding-top: 10px;
`

const InfoContainer = styled.div.attrs({
	className: 'text-secondary',
})`
    margin-top: 10px;
`

const Info = styled.div.attrs({
	className: '',
})``

interface InfoLinkProps {
	isBold?: boolean
}

const InfoLink = styled.a<InfoLinkProps>`
	display: block;
	color: ${ColorConfig.Secondary};
	font-weight: ${props => props.isBold ?? false ? 'bold' : 'inherit'};
	
	&:hover {
		color: ${ColorConfig.Primary};
	}
`

const LinkContainer = styled.div`
	display: flex;
	justify-content: end;
`

const Link = styled.a.attrs({
	className: 'text-muted ms-1 me-1 hover-effect',
	target: '_blank',
	rel: "noreferrer"
})`
`

const Image = styled.img.attrs({
    className: `img-fluid rounded`,
})`
    width: 50px;
    height: 50px;

    @media (max-width: 768px) {
        width: 35px;
        height: 35px;
    }
`

export default Footer