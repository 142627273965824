import RouteContainer from '../../../common/styles/RouteContainer'
import RowSpacer from '../../../common/styles/RowSpacer'
import LibraryLocationSummaryCard from './LibraryLocationSummaryCard'
import LibraryRegionListCard from './LibraryRegionListCard'
import {useParams} from 'react-router-dom'
import useLibraryByRegion from '../useLibraryByRegion'
import RouteTitle from '../../../common/RouteTitle/RouteTitle'
import booksitoutIcon from '../../../config/BooksitoutIcon'
import RouteTitleConfig from '../../../config/RouteTitleConfig'
import useHtmlTitle from "../../../common/useHtmlTitle";

const LibraryRegionRoute = () => {
    const {region, regionDetail} = useParams()
    const [libraries, isLoading] = useLibraryByRegion(region, regionDetail)

    useHtmlTitle(libraries.length !== 0 ? libraries[0].location.name.displayName : null)

    if (isLoading || region == null) {
        return <></>
    }

    return (
        <RouteContainer>
            <RouteTitle
                icon={<booksitoutIcon.library/>}
                title={'도서관'}
                subTitle={''}
                currentKey={'library-region'}
                buttons={RouteTitleConfig.Library}
            />

            <RowSpacer/>
            <LibraryLocationSummaryCard regionName={region} regionDetailName={regionDetail}
                                        libraryCount={libraries.length}/>

            <RowSpacer/>
            <LibraryRegionListCard libraries={libraries}/>

            <RowSpacer/>
        </RouteContainer>
    );
}

export default LibraryRegionRoute