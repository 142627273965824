import {useEffect} from 'react'
import Loading from "../../../common/Loading";
import {BooksitoutServer} from "../../../config/BooksitoutServer";
import AppUserResponse from "../AppUserResponse";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";

const ProfileRedirectRoute = () => {
    const navigate = useNavigate()

    useEffect(() => {
        BooksitoutServer
            .get(`/v1/users/me`)
            .then((res) => {
                const data = res.data as AppUserResponse
                toast.success(`내 프로필로 이동했어요.`)
                navigate(`/users/${data.id}`)
            })
            .catch(() => {
                toast.error(`오류가 났어요. 잠시 후 다시 시도해 주세요.`)
                navigate(`/`)
            })
    }, []);

    return (
        <Loading size={100} message={'이동하고 있어요'}/>
    )
}
export default ProfileRedirectRoute
