import {useEffect, useState} from 'react'
import LibraryResponse from './near/LibraryNearResponse'
import {BooksitoutServer} from '../../config/BooksitoutServer'
import Paging from "../../common/hooks/Paging";

const useLibraryByBookCount = (region: string, size: number) => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isError, setIsError] = useState<boolean>(false)

    const [libraries, setLibraries] = useState<LibraryResponse[]>([])

    const [page, setPage] = useState<number>(1)
    const [isLast, setIsLast] = useState<boolean>(false)
    const [totalPages, setTotalPages] = useState<number>(0)

    const [previousRegion, setPreviousRegion] = useState<string | null>(null)

    useEffect(() => {
        if (region !== previousRegion && page !== 1) {
            setPage(1)
        } else if (page <= 2 && region) {
            setIsLoading(true)

            BooksitoutServer
                .get(`/v1/library?page=${page}&size=${size}&region=${region}`)
                .then(res => {
                    if (previousRegion !== region) {
                        setLibraries(res.data.contents ?? []);
                    } else {
                        setLibraries([...libraries, ...(res.data.contents ?? [])]);
                    }

                    setIsLast(res.data.isLast)
                    setTotalPages(res.data.totalPages)
                    setPreviousRegion(region)
                })
                .catch(() => setIsError(true))
                .finally(() => setIsLoading(false))
        } else {
            setIsLast(true)
        }
    }, [page, region])

    const paging: Paging = {
        hasMore: !isLast,
        totalPages: totalPages,
        fetchNext: () => setPage(page + 1)
    }

    return [libraries, isLoading, isError, paging] as const
}

export default useLibraryByBookCount
