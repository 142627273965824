import styled from 'styled-components';
import BookMineListRange from './BookMineListRange';
import IndexContentContainer from '../../index/IndexContentContainer';
import {Form} from 'react-bootstrap';
import useUrlQuery from '../../../common/hooks/useUrlQuery';

const BookMineRangeSelector = () => {
    const range = useUrlQuery('range') as BookMineListRange

    const buttons = [
        {
            url: '/book/mine?range=READING',
            key: 'READING',
            label: '읽는 중'
        },
        {
            url: '/book/mine?range=DONE',
            key: 'DONE',
            label: '다 읽음'
        },
        {
            url: '/book/mine?range=GIVEUP',
            key: 'GIVEUP',
            label: '포기'
        }
    ];

    return (
        <Container>
            <Label>책 범위</Label>

            <FormContainer>
                <Form.Group>
                    <RangeButtonContainer>
                        {buttons.map(button => <RangeButton key={button.key} href={button.url}
                                                            className={`${range?.toUpperCase() == button.key ? 'text-book fw-bold' : ''}`}>{button.label}</RangeButton>)}
                    </RangeButtonContainer>
                </Form.Group>
            </FormContainer>
        </Container>
    );
}

const Container = styled(IndexContentContainer)`
    display: flex;
    align-items: center;
    justify-content: left;
    
    width: 350px;
    
    color: black;
    
    padding-top: 26px;
    padding-bottom: 24px;

    &:hover {
        color: black;
    }
`;

const Label = styled.h5`
    padding: 3px 0 0;
    margin: 0;
    font-size: 25px;

    &:hover {
        color: black;
    }
`;

const RangeButtonContainer = styled.h6`
    padding: 14px 0 0;
    margin: 0;
`

const RangeButton = styled.a`
    min-width: 30px;
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
`

const FormContainer = styled(Form)`
    width: 200px;
`;

export default BookMineRangeSelector