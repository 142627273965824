import React, {useState} from 'react'
import styled from 'styled-components';
import RouteContainer from "../../../common/styles/RouteContainer";
import useLibraryByBookCount from "../useLibraryByBookCount";
import RouteTitle from "../../../common/RouteTitle/RouteTitle";
import booksitoutIcon from "../../../config/BooksitoutIcon";
import RouteTitleConfig from "../../../config/RouteTitleConfig";
import LibraryCardLoading from "../find/LibraryCardLoading";
import LibraryCard from "../find/LibraryCard";
import RowSpacer from "../../../common/styles/RowSpacer";
import InfiniteScroll from "react-infinite-scroll-component";
import {useNavigate} from "react-router-dom";
import {Form} from "react-bootstrap";
import useUrlQuery from "../../../common/hooks/useUrlQuery";
import toast from "react-hot-toast";

const LibraryRankingRoute = () => {
    const location = useUrlQuery('location')
    const [currentRegion, setCurrentRegion] = useState<string>(location ?? 'ALL')
    const [libraries, isLoading, _, paging] = useLibraryByBookCount(currentRegion, 20)

    return (
        <RouteContainer>
            <RouteTitle
                icon={<booksitoutIcon.library/>}
                title={'책 많은 도서관 순위'}
                subTitle={'책이 많은 순서대로 도서관을 둘러 볼 수 있어요'}
                currentKey={'library'}
                buttons={RouteTitleConfig.Library}

                rightUi={
                    <>
                        <RegionSelect currentRegion={currentRegion} setCurrentRegion={setCurrentRegion}/>
                    </>
                }
            />

            <RowSpacer/>
            {
                isLoading
                    ?
                    <LibraryContainer>
                        <LibraryLoading size={8}/>
                    </LibraryContainer>
                    :
                    <InfiniteScroll
                        next={paging.fetchNext}
                        hasMore={paging.hasMore}
                        dataLength={libraries.length}
                        loader={<LibraryLoading size={4}/>}
                    >
                        <LibraryContainer>
                            {
                                libraries.map(library =>
                                    <Col>
                                        <LibraryCard library={library}/>
                                    </Col>
                                )
                            }
                        </LibraryContainer>
                    </InfiniteScroll>
            }
        </RouteContainer>
    )
}

interface LibraryLoadingProps {
    size: number
}

const LibraryLoading: React.FC<LibraryLoadingProps> = ({size}) => {
    return (
        <>
            {
                Array.from({length: size}).map(_ =>
                    <Col>
                        <LibraryCardLoading/>
                    </Col>
                )
            }
        </>
    )
}

const RegionSelect = ({currentRegion, setCurrentRegion}) => {
    const navigate = useNavigate()

    const regionMap = [
        ['전체', 'ALL'],
        ['서울', 'SEOUL'],
        ['경기', 'GYEONGGIDO'],
        ['인천', 'INCHEON'],
        ['대전', 'DAEJEON'],
        ['대구', 'DAEGU'],
        ['부산', 'BUSAN'],
        ['울산', 'ULSAN'],
        ['광주', 'GWANGJU'],
        ['세종', 'SEJONG'],
        ['강원', 'GANGWON'],
        ['충북', 'CHUNGCHEONGBUKDO'],
        ['충남', 'CHUNGCHEONGNAMDO'],
        ['전북', 'JEONBUK'],
        ['전남', 'JEOLLANAMDO'],
        ['경북', 'GYEONGSANGBUKDO'],
        ['경남', 'GYEONGSANGNAMDO'],
        ['제주', 'JEJU']
    ]

    const postfixMap = [
        ['전체', '로'],
        ['서울', '로'],
        ['경기', '로'],
        ['인천', '으로'],
        ['대전', '으로'],
        ['대구', '로'],
        ['부산', '으로'],
        ['울산', '으로'],
        ['광주', '로'],
        ['세종', '으로'],
        ['강원', '으로'],
        ['충북', '으로'],
        ['충남', '으로'],
        ['전북', '으로'],
        ['전남', '으로'],
        ['경북', '으로'],
        ['경남', '으로'],
        ['제주', '로']
    ]

    return (
        <Form>
            <Form.Select
                onChange={e => {
                    const selectedRegion = regionMap.find(([_, apiName]) => apiName === e.target.value)?.[0] ?? '?';
                    const postfix = postfixMap.find(([apiName]) => apiName === e.target.value)?.[1] ?? '로';
                    toast.success(`지역을 ${selectedRegion}${postfix} 바꿨어요.`)

                    setCurrentRegion(e.target.value)

                    navigate(`/library/ranking?location=${e.target.value}`)
                }}
            >
                {
                    regionMap.map(([displayName, apiName]) => (
                        <option key={apiName} selected={apiName === currentRegion} value={apiName}>
                            {displayName}
                        </option>
                    ))
                }
            </Form.Select>
        </Form>
    )
}

const LibraryContainer = styled.div.attrs({
    className: 'ps-2 pe-2'
})``

const Col = styled.div``

export default LibraryRankingRoute
